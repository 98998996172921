.btn-light {
    background-color: #0071BB !important;
    border-color: #0071BB !important;
    color: #fff !important;
}

#faq a u {  
    color: #fff;
    text-decoration: none !important;
}

#faq a u:visited {  
    color: #fff;
    text-decoration: none !important;
}
