#meta_menu{
  float: none !important;
}
#meta_nav{
  margin: 50px 0 0 0;
}
#api_text_section{
  margin: 40px 0 0 40px;
}
.nav a{
  border: 1px solid black;
}
.nav a:visited{
  border: 1px solid black;
  color: black;
}
.nav a.active {
  border-left: 8px solid #045EA3;
  background-color: #F1F1F1;
}

#myTab{
  float: none !important;
}

#code_block{
  margin: 30px 0 30px 0;
}

#indent{
  margin-left: 50px;
  list-style: disc !important;
}

#indent_right{
  margin-right: 10px;
}

.nav a.active, .nav a {
  color: black;
}

ul{
  list-style-type:disc;
  margin-left: 15px;
}