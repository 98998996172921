#desc_text {
  margin: 20px 0 0 180px;
}
#gallery_row #cards_row_1{
  margin: 20px 0 0 100px;
}
#gallery_row #cards_row_2  {
  margin: 20px 0 0 100px;
}
#gallery_row .card_year{
  font-weight: bold;
}
#gallery_row .card_title{
  font-weight: bold;
  text-decoration: underline;
}
#gallery_row .card{
height:460px;
}

#visualizations_teasers_div {
  /* padding: 2rem; */
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  padding-top: 0px;
  margin-top: 15px;
}

.visualizations_teaser {
  flex: 1;
  /* width: fit-content; */
  height: fit-content;
  /* padding: 10px; */
  padding-bottom: 0px;
  border: none;
  margin: 10px 0px 20px 0px;
  border-radius: 10px;
  position: relative;
}

.visualizations_teaser_img {
  height: 200px;
  display: block;
  margin: auto;
  margin-bottom: 12px;
}

#gallery_row  {
  margin: 20px 0 0 175px;
}

#gallery_row .viz_outer_div {
  padding-left: 0px !important;
  padding-right: 20px !important;
  margin-bottom: 25px;
  width: 380px !important;
}
.visSquare:focus {
  outline: 7px solid #0078a0;
  padding: 3px;
}
.visualizations_teaser_caption,
.teaser_caption,
.visualizations_teaser_caption {
    width: 100%;
    height: 60%;
    background-color: rgba(0, 20, 60, 0.71);
    padding-top: 9%;
    margin: auto;
    text-transform: uppercase;
    position: absolute;
    bottom: 0px;
    text-align: center;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #FFF;
    font-size: 1em;
}
.visualizations_teaser_caption:hover,
.teaser_caption:hover {
    text-decoration: underline;
    
}
#featured_visualization_div .visualizations_teaser_caption{
}
.teaser_caption,
.visualizations_teaser_caption {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
}
#gallery_row .card-body a:visited{
  color: #FFF;
}
.visualizations_teaser_caption_sub {
  font-size: 0.7em;
  color: yellow;
}
.explore_button{
  position: absolute;
  bottom: 10px;
}
