.menuLinks{margin-left: .2px;}
.menuLinks a:visited{color: #0071bc !important}
.menuLinks a{text-decoration: underline; font-size: 0.875rem; padding: 0.25rem 0.5rem;}
.menuLinks .description {padding: 0.25rem 0.5rem 0.25rem 0}
#dataPreview tbody tr:nth-child(odd) {background-color: #fff;}
#dataFields tbody tr:nth-child(odd) {background-color: #fff;}
.codeList{list-style: initial; list-style-position: inside; padding-left: 20px;}
.apiExamples:visited{color: #0071bc}
.tutorial_link{text-decoration: underline;}
.h2Link{font-family: Merriweather,serif; font-weight: 700;font-size: 30px; margin-bottom: 20px; color: #212121; text-decoration: none;}
.h2Link:hover{text-decoration: none;}
.data_preview_text{margin-top: 30px;}
.api_builder_text{margin-top: 70px;}
.contact_link_holder{margin-bottom: 25px; font-weight: bold;}
.contact_link:visited{color: #0071bc}
#fields{margin-top: 10px;}

.snippet_holder{
    margin-top: 60px;
}
#show_more_desc{
    margin-bottom: 3px !important;
}
.api_builder_text a:visited{
    color: #0071bc;
}
#api_text_links a:visited {
    color: #0071bc;
}
#metaLink{
    margin-top: 20px;
    font-weight: bold;
    text-decoration: none;
    color: #0071bc;
}

#metaLink a:visited{
    color: #0071bc;
}

#module a.collapsed:after {
    content: '+ Show Full Description';
    color: rgb(0, 114, 189);
}
  
#module a:not(.collapsed):after {
    content: '- Show Partial Description';
    color: rgb(0, 114, 189);
}

/* Swagger file CSS */
.swagger-ui .opblock.opblock-get {
    background: #fff !important;
    border-color: #0071BB !important;
}
.swagger-ui .opblock.opblock-get .opblock-summary-method {
    background: #0071BB !important;
}
.swagger-ui .opblock.opblock-get .opblock-summary {
    border-color: #0071BB !important;
}
.parameters thead{
    background-color: #D6D7D9;
}
.parameters tbody tr{
    background-color: #F1F1F1;
}
.parameters tbody tr:nth-child(odd){
    background-color: #fff;
}
.responses-table tbody tr{
    background-color: #F1F1F1;
}
.responses-table tbody tr:nth-child(odd){
    background-color: #fff;
}
.responses-table  thead{
    background-color: #D6D7D9;
}
.swagger-ui .info{
    margin:0 !important;
}
.swagger-ui .scheme-container {
    margin: 0 !important;
    padding: 0 !important;
}
.swagger-ui .scheme-container .servers{
    margin-left: 15px;
}
.swagger-ui .wrapper {
    padding: 0 !important;
}
.swagger-ui .servers-title {
    margin-left: 15px;
}