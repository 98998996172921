#main_banner {
  width: 100%;
  height: 300px;
  background-size: auto;
}

#main_banner_dataset {
  width: 100%;
  height: 300px;
  background-size: auto 100%;
}
#main_banner_vis {
  width: 100%;
  height: 300px;
  background-size: 100%;
}

#main_banner_gs {
  width: 100%;
  height: 300px;
  background-size: 100%;
}

.dataset {
  background-image: url("../images/hero-dataset.jpg");
}

.about {
  background-image: url("../images/hero-about.jpg");
}
.started {
  background-image: url("../images/hero-getting-started.png");
}


.api {
  background-image: url("../images/hero-api.jpg");
}
.visualization {
  background-image: url("../images/vis-banner.jpg");
}

.user_guide {
  background-image: url("../images/user-guide-banner2.png");
}

.faq {
  background-image: url("../images/hero-faq.jpg");
}

.homepage {
  background-image: url("../images/hero-homepage.jpg");
}

.meta {
  background-image: url("../images/hero-meta.jpg");
}

.tutorial {
  background-image: url("../images/hero-tutorial.jpg");
}

@media (max-width: 1000px){
  .text_box{
    position: relative;
    top: 100px;
    margin:0 auto;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.79);
    box-sizing: border-box;
    font-family: "Calibri Bold", "Calibri", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: normal;
  }
  .text_box_second_heading{
    position: relative;
    top: 50px;
    margin:0 auto;
    width: 400px;
    padding-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.79);
    box-sizing: border-box;
    font-family: "Calibri Bold", "Calibri", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: normal;
  }
  .text_box_second_heading h1{
    font-size: 2.5em !important;
  }
  .text_box_second_heading h2 {
    font-size: 1.5em !important;
  }
}
@media (min-width: 1000px){
  .text_box{
    top: 100px;
    margin:0 auto;
    width: 966px;
    height: 125px;
    background-color: rgba(255, 255, 255, 0.79);
    box-sizing: border-box;
    font-family: "Calibri Bold", "Calibri", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: normal;
    position: relative;
  }
  .text_box_second_heading{
    position: relative;
    top: 50px;
    margin:0 auto;
    width: 966px;
    height: 190px;
    background-color: rgba(255, 255, 255, 0.79);
    box-sizing: border-box;
    font-family: "Calibri Bold", "Calibri", sans-serif;
    font-weight: 700;
    color: #000000;
    text-align: center;
    line-height: normal;
  }
}

#main_banner h1, #main_banner_dataset h1, #main_banner_vis h1, #main_banner_gs h1 {
  margin-left: 0px;
  padding-top: 15px;
  text-align: center;
  font-size: 4.0em;
  padding-bottom: 20px;
  margin-top: 0px
}

#main_banner h2, #main_banner_dataset h2, #main_banner_vis h2, #main_banner_gs h2 {
  text-align: center;
  font-size: 2.0em;
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

