.agency_abv {
  background-color: rgb(218, 224, 230);
}
.agency_abv:hover {
  background-color: rgb(201, 204, 201);
}

#about .agency_abv {
  background-color: rgb(43, 57, 144);
  color: #fff;
}
#about .agency_abv:hover {
  background-color: #F1F1F1;
  color: #000;
}

.link_button {
  background-color: rgb(0, 114, 189);
  /* color: white !important; */
}

.link_button:hover {
  background-color: rgb(0, 114, 189);
}

.link_button:active {
  background-color: rgb(0, 114, 189);
}

.link_nav:hover {
  background-color: rgb(218, 224, 230);
}

#datasets_filter_tag {
  background-color: #2B3990
}

.border {
  border-width: 2px !important;
}

.clear_filter:hover {
  background-color: rgb(218, 224, 230);
}

.clear_filter {
  background-color: white;
}



#module2 a {
  text-decoration: none;
  color: green;
}

#module2 a:active {
  text-decoration: none;
  color: red;
  background-color: yellow;
}
#module2 a.collapsed:after {
  content: '+ More';
  text-decoration: none;
  color: black;
}

#module2 a:not(.collapsed):after {
  content: '- Less';
  text-decoration: none;
  color: black;
  background-color: pink;
}

#module2 a:hover {
  text-decoration: none;
  color: black;
}

#module p.collapse:not(.show) {
  display: block;
  /* height = lineheight * no of lines to display */
  height: 4.3em;
  overflow: hidden;
}

#module p.collapsing {
  height: 4.5em;
}

.faqButton:link {
  color: black;
}

.faqButton:hover {
  text-decoration: none;
}

.faqButton:active {
  text-decoration: none !important;
}

#faqAnswer {
  border-bottom: 1px solid lightgray;
}

.p-viewer {
  float: right;
  margin-top: -65px;
  margin-left: -40px;
  padding-top: 20px;
  padding-left: 10px;
  position: relative;
  z-index: 3;
  cursor: pointer;
  height: 60px;
  width: 40px;
  /* background-color: red; */
}

.swagger-ui .download-contents {
  background: #4990e2;
  color: #fff;
  border-color: #4990e2;
  margin-right: 1.5em;
}

.swagger-ui .copy-to-clipboard {
  background: #4990e2;
  color: #fff;
  border-color: #4990e2;
  visibility: hidden;
}

.swagger-ui .opblock-body pre.microlight {
  background: #fff !important;
  color: #555 !important;
}

.swagger-ui .opblock-body pre.microlight span {
  color: #555 !important;
}

.swagger-ui textarea.curl {
  background: #fff;
  color: #555 !important;
}

table tbody tr:nth-child(odd) {
  background-color: #e1f3f8;
}

.swagger-ui .auth-wrapper .authorize {
  padding-right: 20px;
  margin-right: 10px;
}
.btn.authorize.unlocked {
  color: red;
  border-color: red;
}
.btn.authorize.unlocked svg {
  fill: red;
}

.btn.authorize.locked {
  color: transparent;
  position: relative;
  display: inline;
  background-color: transparent;
  cursor: pointer;
  line-height: 1;
}

.btn.authorize.locked:after {
  content: 'Authorized';
  float: left;
  color: #49cc90;
  font-size: 14px;
  padding: 5px 2px;
  margin-left: -90px;
}

.body-param-edit {
  display: none;
}
.try-out .btn.try-out__btn {
  padding: 10px;
  width: 300px;
}
.opblock-section {
  position: relative;
}
.opblock-get .try-out {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.swagger-ui .table-container {
  padding: 20px;
  padding-bottom: 75px;
}

.swagger-ui .btn-group {
  border-bottom: 1px solid #4990e2;
  border-top: 1px solid #4990e2;
}

.btn.modal-btn.auth.authorize.button {
  color: transparent;
  position: relative;
}
.btn.modal-btn.auth.authorize.button:after {
  content: 'Login';
  position: absolute;
  display: block;
  color: #49cc90;
  top: 6px;
  left: 33px;
}

.nav-tabs {
  display: flex;
}

.tag-side {
  max-height: 500px;
  overflow-y: scroll;
}

/* Spinner css */
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 6rem;
  height: 6rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  height: 1rem;
  border-width: 0.2em;
}

.update{
  background-color: #2B3990;
  color: white;
}

#agencies_sidebar h3, #categories_sidebar h3, #tags_sidebar h3{
  font-size: 15px;
}

.sub-categories{
text-decoration: underline;
}
/* .btn.authorize.unlocked span {
  display: none;
} */

/* .btn.modal-btn.auth.authorize.button:before {
  content: 'before';
} 

.btn.modal-btn.auth.authorize.button{
  content: 'test';
} 

.btn.modal-btn.auth.authorize.button:after {
  content: 'after';
}  */

/* .swagger-ui .auth-wrapper .authorize:after {
    content: 'logout';
  } */
/* .swagger-ui .try-out__btn.cancel{
    color: yellow;
    background-color: black;
  }

  .swagger-ui .try-out__btn{
    color: lightcoral ;
    background-color: lightseagreen;
    position: relative;
  } */

/* 
.faqButton {
  border: 0;
  background-color: transparent;
  border-radius: 0 ;
} */

/* .try-out
{
  position: relative;
}
.try-out{
  position: absolute;
  bottom: 300px;
  left: 40px;
} */
/*
.opblock-post#operations-pet-uploadFile table.responses-table,
.opblock-get#operations-store-getInventory table.responses-table
{
  margin-bottom: 50px;
}


.opblock-post#operations-pet-addPet .opblock-section,
.opblock-post#operations-user-createUsersWithArrayInput .opblock-section,
.opblock-post#operations-user-createUsersWithListInput .opblock-section,
.opblock-post#operations-user-createUser .opblock-section
{
  position: relative;
}
.opblock-post#operations-pet-addPet .try-out,
.opblock-post#operations-user-createUsersWithArrayInput .try-out,
.opblock-post#operations-user-createUsersWithListInput .try-out,
.opblock-post#operations-user-createUser .try-out
{
  position: absolute;
  bottom: 35px;
  left: 10px;
}

.opblock-put#operations-pet-updatePet .opblock-section,
.opblock-post#operations-store-placeOrder .opblock-section,
.opblock-put#operations-user-updateUser .opblock-section,
.opblock-put#operations-user-updateUser .opblock-section
{
  position: relative;
}
.opblock-put#operations-pet-updatePet .try-out,
.opblock-post#operations-store-placeOrder .try-out,
.opblock-put#operations-user-updateUser .try-out
{
  position: absolute;
  bottom: 35px;
  left: 10px;
}

.opblock-get#operations-pet-findPetsByStatus .opblock-body,
.opblock-get#operations-store-getOrderById .opblock-body,
.opblock-get#operations-store-getInventory .opblock-body,
.opblock-get#operations-user-getUserByName .opblock-body,
.opblock-get#operations-user-loginUser .opblock-body,
.opblock-get#operations-user-logoutUser .opblock-body
{
  position: relative;
}
.opblock-get#operations-pet-findPetsByStatus .try-out
{
  position: absolute;
  bottom: 100px;
  left: 10px;
}


.opblock-get#operations-store-getOrderById .try-out
{
  position: absolute;
  bottom: 150px;
  left: 10px;
}
.opblock-get#operations-store-getInventory .try-out
{
  position: absolute;
  bottom: 20px;
  left: 10px;
}
.opblock-get#operations-user-getUserByName .try-out
{
  position: absolute;
  bottom: 150px;
  left: 10px;
}
.opblock-get#operations-user-loginUser .try-out
{
  position: absolute;
  bottom: 250px;
  left: 10px;
}
.opblock-get#operations-user-logoutUser .try-out
{
  position: absolute;
  bottom: 145px;
  right: 450px;
}


.opblock-deprecated#operations-pet-findPetsByTags .opblock-body
{
  position: relative;
}
.opblock-deprecated#operations-pet-findPetsByTags .try-out
{
  position: absolute;
  bottom: 100px;
  left: 10px;
}

.opblock-get#operations-pet-getPetById .opblock-body
{
  position: relative;
}
.opblock-get#operations-pet-getPetById .try-out
{
  position: absolute;
  bottom: 150px;
  left: 10px;
}

.opblock-delete#operations-pet-deletePet .opblock-body,
.opblock-delete#operations-store-deleteOrder .opblock-body,
.opblock-delete#operations-user-deleteUser .opblock-body
{
  position: relative;
}
.opblock-delete#operations-pet-deletePet .try-out,
.opblock-delete#operations-store-deleteOrder .try-out,
.opblock-delete#operations-user-deleteUser .try-out
{
  position: absolute;
  bottom: 205px;
  right: 450px;
}

.opblock-post#operations-pet-updatePetWithForm .opblock-body
{
  position: relative;
}
.opblock-post#operations-pet-updatePetWithForm .try-out
{
  position: absolute;
  bottom: 145px;
  right: 450px;
}
*/
/* 
.invalid-feedback {
  display: block !important;
} */

.form-check-label {
  margin-top: 4px;
}
.errors_span {
  color: red;
  font-size: 16px;
  font-weight: 400;
}

.half_rem_margin_div {
  margin-top: 0.5rem;
  display: flex;
}

.modal_width {
  width: 50vw; /* Occupy the 50% of the screen width */
  max-width: 50vw;
}

.feedback-container {
  margin-left: -380px;
  margin-bottom: 10px;
}
/* this is to widen input buttons in swagger ui, example filter objects */
.swagger-ui .parameters-col_description input[type='text'] {
  max-width: 736px;
  /*word-wrap: break-word !important;
  word-break: break-all !important; */
  overflow-wrap: break-word;
  /* height: 200px; */
}

.servers-title {
  padding-left: 10px;
}

.parameters-col_description {
  padding-left: 5px !important;
}

.parameters-col_name {
  padding-left: 5px !important;
  padding-top: 30px !important;
}

.col_header.parameters-col_description {
  padding-left: 5px !important;
}

.col_header.parameters-col_name {
  padding-left: 5px !important;
  padding-top: 12px !important;
}

.renderedMarkdown {
  padding-left: 5px !important;
}

.examples-select_section-label {
  padding-left: 5px !important;
}

.responses-header {
  padding-left: 5px !important;
}

.examples-select__section-label {
  padding-left: 5px !important;
}

.col_header.response-col_status {
  padding-left: 5px !important;
}

.col_header.response-col_description {
  padding-left: 5px !important;
  padding-top: 30px !important;
}

.col_header.response-col_links {
  padding-left: 5px !important;
}

.response-col_description {
  padding-left: 5px !important;
}

.response-col_status {
  padding-left: 5px !important;
  padding-top: 30px !important;
}

.response-col_links {
  padding-left: 5px !important;
  padding-top: 30px !important;
}

.col.col_header.response-col_links {
    padding-left: 5px !important;
}

option:first-child {
  font-weight: normal;
}

.table-sm th, .table-sm td {
  padding: 0.3rem;
  max-height: 100px;
  overflow-y: auto;
}

.swagger-ui .opblock .opblock-summary-path {
  /* Overwrite max-width from swagger-ui library that breaks label */
  /* max-width: calc(100% - 110px - 15rem); */
  max-width: 75%;
}
