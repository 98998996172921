.formText {
  margin: 15px 0 15px 0;
}

.inlineRequired{
  display: inline-flex
}

.agency_abv:hover {
  background-color: rgb(201, 204, 201);
}

.link_button {
  background-color: rgb(0, 114, 189);
  /* color: white !important; */
}

/* Create two unequal columns that floats next to each other */
.column {
  float: left;
  padding: 5px;
}

.left {
  width: 45%;
}

.leftShort {
  width: 55%;
}

.right {
  width: 55%;
}

.bumpers{
  margin: 15px 0 15px 0;
}

.headerTextColor{
  color:#0071BC;
}

.requiredText{
  color: red;
}

.errors_span {
  margin-top: 0.25rem;
  font-size: .875rem;
  color: #dc3545;
}

.clickable{
  cursor: pointer;
}

.loginGovImg{
  width: 165px;
}


.clickable:hover {
  outline: 3px solid #0078a0;
  padding: 3px;
}

.clickable:focus {
  outline: 3px solid #0078a0;
  padding: 3px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

#formTextLink{
  margin-left: 4px;
}

