.formText {
  margin: 15px 0 15px 0;
}

.inlineRequired {
  display: inline-flex
}

.agency_abv:hover {
  background-color: rgb(201, 204, 201);
}

.link_button {
  background-color: rgb(0, 114, 189);
}

/* Create two unequal columns that floats next to each other */
.column {
  float: left;
  padding: 5px;
}

.left {
  width: 45%;
}

.leftShort {
  width: 55%;
}

.right {
  width: 55%;
}

.bumper{
  margin-bottom: 15px;
}

.headerTextColor{
  color:#0071BC;
}

.requiredText{
  color: red;
}

.errors_span {
  margin-top: 0.25rem;
  font-size: .875rem;
  color: #dc3545;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.labelHeight{
margin-top: 1.1rem !important;
}

[type=checkbox]+label::before{height: 1rem !important; width: 1rem !important; line-height: .5rem !important;}
[type=checkbox]+label {margin-bottom: .4rem !important;}
.termsBlock{margin-bottom: .2rem !important;}
#questions select.form-control:not([size]):not([multiple]) {height: calc(1.75rem + 2px) !important;}
select{padding: 0.275rem 0.55rem !important;}

