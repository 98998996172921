#meta_menu{
  float: none !important;
}
#meta_nav{
  margin: 50px 0 0 0;
}
#meta_text_section{
  margin: 50px 0 0 20px;
}
.nav a{
  border: 1px solid black;
}
.nav a:visited{
  border: 1px solid black;
  color: black;
}
.nav a.active {
  border-left: 8px solid #045EA3;
  background-color: #F1F1F1;
}
.btc-accordion .card {
  cursor: pointer;
  color: #000000;
}

.btc-accordion .card-header {
  background-color: rgba(0, 113, 188, 1);
}

.btc-accordion .card button {
  color: #f2f2f2;
  text-decoration: none;
  background-color: #0071bc;
  width: 100%;
  border: none;
  text-align: left;
}

.accordion-icon[aria-expanded="false"] .fa-angle-down {
  transform: rotate(0deg);
}

.accordion-icon[aria-expanded="true"] .fa-angle-down {
  transform: rotate(180deg);
  transition: 0.5s;
}
#meta_accordian{
  margin-top: 30px;
}
#meta_accordian ul{
  list-style: disc !important;
  margin-left: 25px;
}
.nav a.active, .nav a {
  color: black;
}

ol{
  list-style-type:disc;
  margin-left: 15px;
}