#meta_menu{
  float: none !important;
}
#meta_nav{
  margin: 50px 0 0 0;
}
#meta_text_section{
  margin: 50px 0 0 50px;
}
.nav a{
  border: 1px solid black;
}
.nav a:visited{
  border: 1px solid black;
  color: black;
}
.nav a.active {
  border-left: 8px solid #045EA3;
  background-color: #F1F1F1;
}

.nav a.active, .nav a {
  color: black;
}

#video_player iframe {
  border: none;
}
