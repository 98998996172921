.loginGovLoader{
    margin-top: 100px;
}
.hideToggle{
    display:none !important
}
.showMore{
    color: #0071BC; cursor: pointer;
}
.camera_icon{
    height: 40px; width: 34px;
}
.tutorial_link:visited{
    color: #0071BC;
}
.enforcement_link{
    margin: 0 0 10px 0
}
#dol-primary-menu li a{
text-transform: none !important;
font-weight:normal !important;
font-family: "Arial Black", Arial, sans-serif;
padding-left: 35px !important;
padding-right: 35px !important;
}
#header_subsection{
   width: 428px; 
   margin: 15px 50px 0 0;
   color: #0071bc;
   font-weight: 500;
}
#header_subsection a:visited{
    color: #0071bc;
 }
 #header_subsection span{
     text-decoration: none;
}
 @media (min-width: 993px) {
    .signin_mobile {
        display: none !important;
    }
}
#beta_text{
    font-size: .7em;
    color: #0071bc;
    margin-left: 4px;
}
#breadcrumbs-container ul.breadcrumbs{
    margin-left: 30px !important;
}

.container.agency-name h2{
  margin-left: 30px  !important;
}
